<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">填写备案信息</div>
                <div class="crumb-box">
                    <div :class="['crumb',(index < 3) ? 'active':'']" v-for="item,index in crumbArray" :key="index">{{item.title}}</div>
                </div>
            </div>
        </div>

        <!-- 表单区 -->
        <form :class="['formorder','form','needs-validation','w-box',fromClass ? 'was-validated':'']" novalidate>
            <!-- 订单信息 -->
            <div class="order-info">
                <div class="area-title">订单信息</div>
                <div class="info-list bgf">
                    <div class="list-box">
                        <div class="list">
                            <div class="list-title">用户名称</div>
                            <div class="list-content">
                                <div class="content">{{orderInfo.user_name}}</div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="list-title fb-two">套餐</div>
                            <div class="list-content">
                                <div class="content">{{orderInfo.goods_name}}</div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="list-title fb-three">备案性质</div>
                            <div class="list-content">
                                <div class="content">{{orderInfo.type_name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="list-box">
                        <div class="list">
                            <div class="list-title">主办单位名称</div>
                            <div class="list-content">
                                <input type="text" class="form-control inp verify_input" required name="主办单位或主办人名称" v-model="orderInfo.subject_name" placeholder="请填写" />
                            </div>
                        </div>
                        <div class="list">
                            <div class="list-title fb-two">小程序名称</div>
                            <div class="list-content">
                                <input type="text" class="form-control inp verify_input" required name="小程序名称" v-model="orderInfo.app_name" placeholder="请填写" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 主办单位信息 -->
            <div class="sponsor-info">
                <div class="area-title">主办单位信息</div>
                <!-- 营业执照上传 -->
                <div class="id-photo bgf">
                    <div class="upload-box">
                        <!-- 上传 -->
                        <div class="upload">
                            <div class="file-box">
                                <!-- 上传营业执照 -->
                                <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="主办单位证件" ref="fileInput1" @change="handleFileUpload(1)" />
                                <img src="@/assets/img/else/add.png" alt class="icon" />
                                <!-- 已上传的图片 -->
                                <div v-if="certificate_file" class="upload-img">
                                    <img :src="'https://bapi.aibtb.com'+certificate_file" alt="营业执照" />
                                </div>
                            </div>
                            <div class="warning-text" v-if="!certificate_file">请上传营业执照</div>
                            <div class="title">上传营业执照</div>
                        </div>
                        <!-- 示例图 -->
                        <div class="example">
                            <div class="img-box">
                                <img src="@/assets/img/else/sl_img.png" alt />
                            </div>
                            <div class="title">示例图</div>
                        </div>
                    </div>
                    <!-- 证件类型 -->
                    <div class="type-id">
                        <div class="title">证件类型【营业执照】</div>
                        <select class="form-select inp" v-model="certificate_type">
                            <option>营业执照（个人或企业）</option>
                            <option>组织机构代码证</option>
                            <option>外国企业常驻代表机构登记证</option>
                        </select>
                    </div>
                </div>
                <!-- 填写信息 -->
                <div class="info-box bgf pr-1">
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-1">所属区域</label>
                            <div class="area-select">
                                <div>
                                    <select class="form-select verify_input" required v-html="provinceHtml" @change="provinceChange" v-model="province_name"></select>
                                </div>
                                <div>
                                    <select class="form-select verify_input" required v-html="cityHtml" @change="cityChange" v-model="city_name"></select>
                                </div>
                                <div>
                                    <select class="form-select verify_input" required v-html="areaHtml" v-model="area_name"></select>
                                </div>
                            </div>
                        </div>
                        <template v-if="isBiz">
                            <div class="info-list">
                                <label class="form-label fx-2">
                                    法定代表人姓名
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control add_inp verify_input" required name="法定代表人姓名" v-model="userInfo.Name" placeholder="请填写" />
                            </div>
                        </template>
                    </div>
                    <div class="list" v-if="isBiz">
                        <div class="info-list">
                            <label class="form-label fx-1">
                                证件号码
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control add_inp verify_input" required name="证件号码" v-model="licenseInfo.RegNum" placeholder="请填写" />
                        </div>
                        <div class="info-list" v-show="userInfo.IdNum !== ''">
                            <label class="form-label fx-2">
                                法定代表人证件号码
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control add_inp verify_input" required name="法定代表人证件号码" minlength="18" maxlength="18" v-model="userInfo.IdNum" placeholder="请填写" />
                        </div>
                        <!-- <div class="info-list">
                            <label class="form-label">备案号</label>
                            <input type="text" class="form-control add_inp" name="备案号" v-model="icp_number" placeholder="请填写" />
                        </div>-->
                    </div>
                    <div class="list">
                        <template v-if="isBiz">
                            <div class="info-list">
                                <label class="form-label fx-1">
                                    证件住所
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control add_inp verify_input" required name="证件住所" placeholder="精确到门牌号" v-model="licenseInfo.Address" />
                            </div>
                        </template>
                        <div class="info-list">
                            <label :class="['form-label',isBiz == true ? 'fx-2' : 'fx-1']">
                                通信地址
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control add_inp verify_input" required name="通信地址" placeholder="不要省市区，只要地址，精确到门牌号" v-model="contact_address" />
                        </div>
                    </div>
                    <div class="info-list">
                        <label class="form-label fx-1">地址备注</label>
                        <input type="text" class="form-control add_inp" name="通信地址备注" placeholder="地址的其他说明" v-model="contact_remark" />
                    </div>
                    <div class="tips">
                        <div class="tips-title">温馨提示:</div>
                        <div class="text-box">
                            <div class="text">
                                1、通信地址需要精确到具体门牌号，地址开头的省市区不用填入，如果没有门牌号请在下方&nbsp;“
                                <span>通信地址备注</span>”&nbsp;说明情况，或者和证件住址保持一致。
                            </div>
                            <div class="text">2、如主办单位在其他平台有做过备案，填写前请与我们客服联系，按相关要求进行准确填写。</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 负责人信息 -->
            <div class="principal-info">
                <div class="area-title">主体负责人信息</div>
                <!-- 身份证上传 -->
                <div class="upload-area bgf">
                    <!-- 上传身份证正面 -->
                    <div class="front upload">
                        <div class="file-box">
                            <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="主体负责人身份证件正面" ref="fileInput2" @change="handleFileUpload(2)" />
                            <div class="icon-box1">
                                <div class="icon-box2">
                                    <img src="@/assets/img/else/front.png" alt class="icon" />
                                </div>
                            </div>
                            <!-- 已上传的图片 -->
                            <div v-if="corp_file_front" class="upload-img">
                                <img :src="'https://bapi.aibtb.com'+corp_file_front" />
                            </div>
                        </div>
                        <div class="warning-text" v-if="!corp_file_front">请上传身份证正面</div>
                        <div class="title">
                            上传身份证
                            <span>正面</span>
                        </div>
                    </div>
                    <!-- 上传身份证反面 -->
                    <div class="back upload">
                        <div class="file-box">
                            <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="主体负责人身份证件反面" ref="fileInput3" @change="handleFileUpload(3)" />
                            <div class="icon-box1">
                                <div class="icon-box2">
                                    <img src="@/assets/img/else/back.png" alt class="icon" />
                                </div>
                            </div>
                            <!-- 已上传的图片 -->
                            <div v-if="corp_file_back" class="upload-img">
                                <img :src="'https://bapi.aibtb.com'+corp_file_back" />
                            </div>
                        </div>
                        <div class="warning-text" v-if="!corp_file_back">请上传身份证反面</div>
                        <div class="title">
                            上传身份证
                            <span>反面</span>
                        </div>
                    </div>
                </div>
                <div class="info-box bgf pr-2">
                    <template v-if="isUser">
                        <div class="list">
                            <div class="info-list">
                                <label class="form-label fx-3">主体负责人证件类型</label>
                                <select class="form-select add_inp" name="主体负责人证件类型" v-model="corp_id_type">
                                    <option>居民身份证</option>
                                    <option>护照</option>
                                    <option>台湾居民来往大陆通行证</option>
                                    <option>港澳居民来往内地通行证</option>
                                    <option>外国人永久居留身份证</option>
                                    <option>港澳居民居住证</option>
                                    <option>台湾居民居住证</option>
                                </select>
                            </div>
                            <div class="info-list">
                                <label class="form-label fx-5">
                                    主体负责人姓名
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control add_inp verify_input" required name="主体负责人姓名" v-model="userInfo.Name" placeholder="请填写" />
                            </div>
                        </div>
                        <div class="list">
                            <div class="info-list">
                                <label class="form-label fx-3">
                                    主体负责人证件号码
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control add_inp verify_input" required name="主体负责人证件号码" minlength="18" maxlength="18" v-model="userInfo.IdNum" placeholder="请填写" />
                            </div>
                            <div class="info-list">
                                <label class="form-label fx-5">
                                    证件有效期日期
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="date-box d-flex align-items-center verify_input">
                                    <input type="text" class="form-control" required name="主体负责人证件有效期起始日期" placeholder="起始日期" v-model="userInfo.BeginDate" />
                                    <div class="icon"></div>
                                    <input type="text" class="form-control verify_input" required name="终止日期" placeholder="终止日期" v-model="userInfo.EndDate" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-3">
                                主体负责人联系电话
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="tel"
                                class="form-control add_inp verify_input"
                                minlength="11"
                                maxlength="11"
                                required
                                name="主体负责人联系电话"
                                v-model="corp_phone"
                                @change="checkPhoneNumber(corp_phone,'corp_phone')"
                                placeholder="请填写"
                            />
                        </div>
                        <div class="info-list">
                            <label class="form-label fx-5">
                                主体负责人应急电话
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="tel"
                                class="form-control add_inp verify_input"
                                required
                                name="主体负责人应急联系电话"
                                minlength="11"
                                maxlength="11"
                                v-model="corp_phone_back"
                                @change="checkPhoneNumber(corp_phone_back,'corp_phone_back')"
                                placeholder="请填写"
                            />
                        </div>
                    </div>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-3">
                                主体负责人电子邮箱
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                class="form-control add_inp verify_input"
                                required
                                name="主体负责人电子邮箱地址"
                                v-model="corp_email"
                                @change="checkEmail(corp_email,'corp_email')"
                                placeholder="请填写"
                            />
                        </div>
                        <div class="info-list">
                            <label class="form-label fx-5">主体信息备注</label>
                            <input type="text" class="form-control add_inp" name="主体信息备注" placeholder="请根据需求，如实填写即可" v-model="corp_remark" />
                        </div>
                    </div>
                    <div class="tips">
                        <div class="tips-title">温馨提示:</div>
                        <div class="text">
                            主体负责人须为本企业（组织）的法人；联系电话和应急联系电话
                            <span>不能相同</span>。
                        </div>
                    </div>
                </div>
            </div>
            <!-- 小程序信息 -->
            <div class="app-info">
                <div class="area-title">小程序信息</div>
                <div class="info-box bgf pr-3">
                    <div class="info-list md-1">
                        <label class="form-label fx-4">
                            小程序服务内容
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control add_inp verify_input" required name="小程序服务内容" placeholder="请填写您的小程序需对外开放的服务内容" v-model="app_service" />
                    </div>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-4">服务类型</label>
                            <div class="sever-box">
                                <div>
                                    <select class="form-select drptype1 verify_input" required name="一级类型" v-html="firstTypeHtml" v-model="app_type1" @change="firstTypeChange"></select>
                                </div>
                                <div>
                                    <select class="form-select drptype2 verify_input" required name="二级类型" v-html="lastTypeHtml" v-model="app_type2"></select>
                                </div>
                            </div>
                        </div>
                        <div class="info-list">
                            <label class="form-label fx-5">小程序语言</label>
                            <select class="form-select drplang add_inp verify_input" required name="小程序语言" v-model="app_lang">
                                <option>简体中文</option>
                                <option>繁体中文</option>
                                <option>英语</option>
                            </select>
                        </div>
                    </div>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-4">
                                小程序ID
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control add_inp verify_input" required minlength="18" maxlength="18" placeholder="wx开头18位" name="小程序ID" v-model="app_id" />
                        </div>
                        <div class="info-list">
                            <label class="form-label fx-5">前置或专项审批内容类型</label>
                            <select class="form-select drpqzsp add_inp verify_input" required name="前置或专项审批内容类型" v-model="app_content_type">
                                <option>不涉及</option>
                                <option>新闻</option>
                                <option>出版</option>
                                <option>教育</option>
                                <option>医疗保健</option>
                                <option>药品和医疗器械</option>
                                <option>电子公告服务</option>
                                <option>文化</option>
                                <option>广播电影电视节目</option>
                                <option>网络预约车</option>
                                <option>互联网金融</option>
                                <option>校外培训</option>
                                <option>宗教</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="info-list">
                        <label class="form-label">小程序备案号</label>
                        <input type="text" class="form-control add_inp" name="小程序备案号" placeholder="请填写" v-model="app_icp_number" />
                    </div>-->
                    <div class="info-list">
                        <label class="form-label fx-4">小程序其他备注</label>
                        <input type="text" class="form-control add_inp" name="小程序备注" placeholder="请根据需求，如实填写即可" v-model="app_remark" />
                    </div>
                    <div class="tips">
                        <div class="tips-title">温馨提示:</div>
                        <div class="text">
                            如您的小程序从事新闻、出版、药品和医疗器械、网约车等需须经有关主管部门审核同意的互联网信息服务，请选择
                            <span>前置或专项审批内容</span>类型，并在本页下方材料上传处提供业务对应的前置审批证明文件。
                        </div>
                    </div>
                </div>
            </div>
            <!-- 小程序负责人信息 -->
            <div class="app-principal">
                <div class="area-title">小程序负责人信息</div>
                <!-- 身份证上传 -->
                <div class="upload-area bgf">
                    <!-- 上传身份证正面 -->
                    <div class="front upload">
                        <div class="file-box">
                            <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="小程序负责人身份证件正面" ref="fileInput4" @change="handleFileUpload(4)" />
                            <div class="icon-box1">
                                <div class="icon-box2">
                                    <img src="@/assets/img/else/front.png" alt class="icon" />
                                </div>
                            </div>
                            <!-- 已上传的图片 -->
                            <div v-if="app_corp_file_front" class="upload-img">
                                <img :src="'https://bapi.aibtb.com'+app_corp_file_front" />
                            </div>
                        </div>
                        <div class="warning-text" v-if="!app_corp_file_front">请上传身份证正面</div>
                        <div class="title">
                            上传身份证
                            <span>正面</span>
                        </div>
                    </div>
                    <!-- 上传身份证反面 -->
                    <div class="back upload">
                        <div class="file-box">
                            <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="小程序负责人身份证件反面" ref="fileInput5" @change="handleFileUpload(5)" />
                            <div class="icon-box1">
                                <div class="icon-box2">
                                    <img src="@/assets/img/else/back.png" alt class="icon" />
                                </div>
                            </div>
                            <!-- 已上传的图片 -->
                            <div v-if="app_corp_file_back" class="upload-img">
                                <img :src="'https://bapi.aibtb.com'+app_corp_file_back" />
                            </div>
                        </div>
                        <div class="warning-text" v-if="!app_corp_file_back">请上传身份证反面</div>
                        <div class="title">
                            上传身份证
                            <span>反面</span>
                        </div>
                    </div>
                </div>
                <div class="info-box bgf pr-3">
                    <template v-if="isAppUser">
                        <div class="list">
                            <div class="info-list">
                                <label class="form-label fx-6">
                                    小程序负责人证件类型
                                    <span class="text-danger">*</span>
                                </label>
                                <select class="form-select add_inp wd-1" name="小程序负责人证件类型" v-model="app_corp_id_type">
                                    <option>居民身份证</option>
                                    <option>护照</option>
                                    <option>台湾居民来往大陆通行证</option>
                                    <option>港澳居民来往内地通行证</option>
                                    <option>外国人永久居留身份证</option>
                                    <option>港澳居民居住证</option>
                                    <option>台湾居民居住证</option>
                                </select>
                            </div>
                            <div class="info-list">
                                <label class="form-label fx-5">
                                    小程序负责人姓名
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control add_inp verify_input" required name="小程序负责人姓名" v-model="app_corp_name" placeholder="请填写" />
                            </div>
                        </div>
                        <div class="list">
                            <div class="info-list">
                                <label class="form-label fx-6">
                                    小程序负责人证件号码
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    class="form-control add_inp wd-1 verify_input"
                                    required
                                    minlength="18"
                                    maxlength="18"
                                    name="小程序负责人证件号码"
                                    v-model="app_corp_id_number"
                                    placeholder="请填写"
                                />
                            </div>
                            <div class="info-list">
                                <label class="form-label fx-5">
                                    证件有效期日期
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="date-box d-flex align-items-center">
                                    <input type="text" class="form-control verify_input" required name="小程序负责人证件有效期起始日期" placeholder="起始日期" v-model="app_corp_id_begindate" />
                                    <div class="icon"></div>
                                    <input type="text" class="form-control verify_input" required name="小程序负责人证件有效期终止日期" placeholder="终止日期" v-model="app_corp_id_enddate" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-6">
                                小程序负责人联系电话
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control add_inp wd-1 verify_input"
                                required
                                name="小程序负责人联系电话"
                                minlength="11"
                                maxlength="11"
                                v-model="app_corp_phone"
                                @change="checkPhoneNumber(app_corp_phone,'app_corp_phone')"
                                placeholder="请填写"
                            />
                        </div>
                        <div class="info-list">
                            <label class="form-label fx-5">
                                小程序负责人应急电话
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="tel"
                                class="form-control add_inp verify_input"
                                required
                                name="小程序负责人应急联系电话"
                                minlength="11"
                                maxlength="11"
                                v-model="app_corp_phone_back"
                                @change="checkPhoneNumber(app_corp_phone_back,'app_corp_phone_back')"
                                placeholder="请填写"
                            />
                        </div>
                    </div>
                    <div class="list">
                        <div class="info-list">
                            <label class="form-label fx-6">
                                小程序负责人电子邮箱
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                class="form-control add_inp wd-1 verify_input"
                                required
                                name="小程序负责人电子邮箱地址"
                                v-model="app_corp_email"
                                @change="checkEmail(app_corp_email,'app_corp_email')"
                                placeholder="请填写"
                            />
                        </div>
                    </div>
                    <div class="tips">
                        <div class="tips-title">温馨提示:</div>
                        <div class="text-box">
                            <div class="text">
                                1、当⼩程序负责⼈不是法⼈时，需要在本页下方材料上传处，提供
                                <span>⼩程序负责⼈授权书</span>。
                            </div>
                            <div class="text">
                                2、联系电话和应急联系电话不能相同，小程序负责人信息和主体负责人不是同一人时，需要准备
                                <span>4个手机号</span>。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 其他材料上传 -->
            <div class="other-material">
                <div class="area-title">其他材料上传</div>
                <div class="material-box bgf">
                    <div class="material-list">
                        <div class="list-item">
                            <div class="list-content">
                                <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="互联网信息服务备案承诺书" ref="fileInput6" @change="handleFileUpload(6)" />
                                <img src="@/assets/img/else/add.png" alt class="icon" />
                                <div v-if="agreement1" class="upload-img">
                                    <img :src="'https://bapi.aibtb.com'+agreement1" />
                                </div>
                            </div>
                            <div class="warning-text" v-if="!agreement1">请上传承诺书</div>
                            <div class="list-head">
                                <div class="list-title">
                                    互联网信息服务备案承诺书
                                    <span class="text-danger">*</span>
                                </div>
                                <div>
                                    <a
                                        href="https://beian.idss-cn.com/static/files/%E4%BA%92%E8%81%94%E7%BD%91%E4%BF%A1%E6%81%AF%E6%9C%8D%E5%8A%A1%E5%A4%87%E6%A1%88%E6%89%BF%E8%AF%BA%E4%B9%A6.docx"
                                        target="_blank"
                                    >下载模版并打印</a>
                                </div>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="list-content">
                                <input type="file" accept="image/*" class="form-control file-inp upload-inp" name="互联网信息备案用户真实身份信息核验单" ref="fileInput7" @change="handleFileUpload(7)" />
                                <img src="@/assets/img/else/add.png" alt class="icon" />
                                <div v-if="agreement2" class="upload-img">
                                    <img :src="'https://bapi.aibtb.com'+agreement2" />
                                </div>
                            </div>
                            <div class="warning-text" v-if="!agreement2">请上传核验单</div>
                            <div class="list-head">
                                <label class="list-title">
                                    互联网信息备案用户真实身份信息核验单
                                    <span class="text-danger">*</span>
                                </label>
                                <div>
                                    <a
                                        href="https://beian.idss-cn.com/static/files/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E5%A4%87%E6%A1%88%E4%BF%A1%E6%81%AF%E7%9C%9F%E5%AE%9E%E6%80%A7%E6%A0%B8%E9%AA%8C%E5%8D%95.docx"
                                        target="_blank"
                                    >下载模版并打印</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="else-cl">
                        <div class="title-box" @click="fold('else')">
                            <div class="else-title">其他材料文件</div>
                            <div class="fold-icon">
                                <img src="@/assets/img/else/chevron-left.png" alt />
                            </div>
                        </div>
                        <div class="material-list" v-show="elseArea">
                            <div class="list-item">
                                <div class="list-content">
                                    <input type="file" accept="image/*" class="form-control file-inp" name="法人主体授权书" ref="fileInput8" @change="handleFileUpload(8)" />
                                    <img src="@/assets/img/else/add.png" alt class="icon" />
                                    <div v-if="agreement3" class="upload-img">
                                        <img :src="'https://bapi.aibtb.com'+agreement3" />
                                    </div>
                                </div>
                                <div class="list-head">
                                    <label class="list-title">主体负责人非法人时需要授权书</label>
                                    <div>
                                        <a
                                            href="https://beian.idss-cn.com/static/files/%E4%B8%BB%E4%BD%93%E8%B4%9F%E8%B4%A3%E4%BA%BA%E6%8E%88%E6%9D%83%E4%B9%A6%E6%A8%A1%E7%89%88.docx"
                                            target="_blank"
                                        >下载模版并打印</a>
                                    </div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="list-content">
                                    <input type="file" accept="image/*" class="form-control file-inp" name="法人负责人授权书" ref="fileInput9" @change="handleFileUpload(9)" />
                                    <img src="@/assets/img/else/add.png" alt class="icon" />
                                    <div v-if="agreement4" class="upload-img">
                                        <img :src="'https://bapi.aibtb.com'+agreement4" />
                                    </div>
                                </div>
                                <div class="list-head">
                                    <label class="list-title">小程序负责人非法人时需要</label>
                                    <div>
                                        <a
                                            href="https://beian.idss-cn.com/static/files/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E8%B4%9F%E8%B4%A3%E4%BA%BA%E6%8E%88%E6%9D%83%E4%B9%A6%E6%A8%A1%E7%89%88.docx"
                                            target="_blank"
                                        >下载模版并打印</a>
                                    </div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="list-content">
                                    <input type="file" accept="image/*" class="form-control file-inp" name="其他材料文件" ref="fileInput10" @change="handleFileUpload(10)" />
                                    <img src="@/assets/img/else/add.png" alt class="icon" />
                                    <div v-if="agreement5" class="upload-img">
                                        <img :src="'https://bapi.aibtb.com'+agreement5" />
                                    </div>
                                </div>
                                <div class="list-head">
                                    <label class="list-title">其他材料文件</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tips ma-1">
                        <div class="tips-title">温馨提示:</div>
                        <div class="text-box">
                            <div class="text">
                                1、为了提高通过率，建议使用
                                <span>原件或者原件扫描件</span>。
                            </div>
                            <div class="text">2、请根据需要进行上传，包括但不限于党建证明、域名实名认证裁图、主体更名函、居住证、情况说明、小程序备案信息真实性承诺书、主体其它附件、小程序其它附件等。</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 备注信息 -->
            <div class="postscript">
                <div class="area-title">备注信息</div>
                <div class="c-box bgf">
                    <textarea class="form-control add_inp" name="remark" v-model="remark" placeholder="请根据需求，如实填写即可"></textarea>
                </div>
            </div>

            <!-- 操作按钮 -->
            <div class="reviewing">
                <div class="btn-box">
                    <button class="draft-btn" type="button" @click="saveInfo">暂存信息</button>
                    <button class="btn btn-primary save-btn linear-bg" type="button" @click="submitInfo">正式提交</button>
                </div>
            </div>
        </form>

        <!-- 模态框 -->
        <div class="mark_bg" v-if="isModel">
            <div class="content_box">
                <img src="@/assets/img/else/pass.png" alt />
                <div class="tip_text">备案信息提交完成啦~</div>
                <div class="btn_box">
                    <button type="button" class="btn btn-primary go_btn" @click="goPage('detailinfo',true)">前往查看</button>
                    <button type="button" class="go_affirm" @click="goPage('examine',true)">去认证</button>
                </div>
            </div>
        </div>

        <!-- 错误模态框 -->
        <transition name="fade">
            <div class="error_mark" v-if="isError">
                <div class="error_box">
                    <img src="@/assets/img/else/error.png" alt />
                    <!-- <div class="tip_error" v-for="item,index in firstEntryArray" :key="index">{{item[1][0]}}</div> -->
                    <div class="tip_error">表单验证失败</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fromClass: false, //from表单验证样式
            inputs: [], //需要判断是否为空的input框
            uploadInp: [], //必填的文件上传框
            orderId: '', //订单ID
            orderInfo: '', //订单信息
            provinceHtml: '', //省列表
            province_name: '', //省
            cityHtml: '', //市列表
            city_name: '', //市
            areaHtml: '', //区列表
            area_name: '', //区
            certificate_type: '营业执照（个人或企业）', //证件类型（默认）
            certificate_number: '', //证件号码
            icp_number: '', //备案号
            certificate_address: '', //证件住所
            contact_address: '', //通信地址
            contact_remark: '', //通信地址备注
            corp_name: '', //法定代表人姓名
            corp_id_type: '居民身份证', //主体负责人证件类型（默认）
            corp_id_number: '', //主体负责人证件号码
            corp_id_begindate: '', //证件有效期起始日期
            corp_id_enddate: '', //证件有效期终止日期
            corp_phone: '', //主体负责人联系电话
            corp_phone_back: '', //主体负责人应急联系电话
            corp_email: '', //主体负责人电子邮箱地址
            corp_remark: '', //主体信息备注
            app_service: '', //小程序服务内容
            firstTypeHtml: '', //服务类型一级列表
            app_type1: '', //服务类型1
            lastTypeHtml: '', //服务类型二级列表
            app_type2: '', //服务类型2
            app_lang: '简体中文', //小程序语言（默认）
            app_content_type: '不涉及', //前置或专项审批内容类型（默认）
            app_remark: '', //小程序备注
            app_icp_number: '', //小程序备案号
            app_id: '', //小程序ID
            app_corp_name: '', //小程序负责人姓名
            app_corp_id_type: '居民身份证', //小程序负责人证件类型（默认）
            app_corp_id_number: '', //小程序负责人证件号码
            app_corp_id_begindate: '', //证件有效期起始日期
            app_corp_id_enddate: '', //证件有效期终止日期
            app_corp_phone: '', //小程序负责人联系电话
            app_corp_phone_back: '', //小程序负责人应急联系电话
            app_corp_email: '', //小程序负责人电子邮箱地址

            certificate_file: '', //主办单位营业执照（个人或企业)
            corp_file_front: '', //主体负责人证件照正面
            corp_file_back: '', //主体负责人证件照反面
            app_corp_file_front: '', //小程序负责人证件照正面
            app_corp_file_back: '', //小程序负责人证件照反面
            agreement1: '', //互联网信息服务备案承诺书
            agreement2: '', //互联网信息备案用户真实身份信息核验单
            agreement3: '', //主体负责人非法人
            agreement4: '', //小程序负责人非法人
            agreement5: '', //其他材料文件
            remark: '', //备注

            errorData: '', //错误信息

            //营业执照信息
            licenseInfo: {
                Address: '',
                Business: '',
                Capital: '',
                ComposingForm: '',
                IsDuplication: '',
                Name: '',
                Period: '',
                Person: '',
                RegNum: '',
                RegistrationDate: '',
                RequestId: '',
                SetDate: '',
                Type: '',
                RecognizeWarnCode: '',
                RecognizeWarnMsg: '',
            },
            //用户身份证信息
            userInfo: {
                Address: '',
                AdvancedInfo: '',
                Authority: '',
                Birth: '',
                IdNum: '',
                Name: '',
                Nation: '',
                RequestId: '',
                Sex: '',
                BeginDate: '',
                EndDate: '',
            },

            // 模态框
            isModel: false,

            //面包屑
            crumbArray: [
                {
                    title: '确认订单',
                },
                {
                    title: '支付订单',
                },
                {
                    title: '信息填写',
                },
                {
                    title: '认证核验',
                },
                {
                    title: '完成',
                },
            ],

            // 用户名
            user: '',

            // 其他材料是否折叠
            elseArea: false,

            // 用户上传营业执照图片前折叠
            isBiz: false,

            // 用户上传证件图片前折叠
            isUser: false,

            // 小程序用户上传证件图片前折叠
            isAppUser: false,

            //错误提示
            firstEntryArray: '',

            // 错误弹框
            isError: false,
        }
    },
    watch: {
        userInfo: {
            handler(newValue) {
                // console.log('userInfo 变化了', newValue)
                // 在这里可以执行你想要的操作
                this.app_corp_name = newValue.Name
                this.app_corp_id_number = newValue.IdNum
                this.app_corp_id_begindate = newValue.BeginDate
                this.app_corp_id_enddate = newValue.EndDate
            },
            deep: true,
        },
        corp_phone(newValue) {
            this.app_corp_phone = newValue //小程序负责人联系电话
        },
        corp_phone_back(newValue) {
            this.app_corp_phone_back = newValue //小程序负责人应急联系电话
        },
        corp_email(newValue) {
            this.app_corp_email = newValue //小程序负责人电子邮箱地址
        },
    },
    methods: {
        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        // console.log('detailData', res.data.data)
                        this.orderInfo = res.data.data
                        if (res.data.data.detail) {
                            let info = res.data.data.detail
                            // console.log('info', info)
                            this.province_name = info.province_name
                            this.city_name = info.city_name
                            this.area_name = info.area_name
                            // console.log(info.province_name, info.city_name, info.area_name)
                            this.certificate_type = info.certificate_type
                            this.licenseInfo.RegNum = info.certificate_number
                            this.icp_number = info.icp_number
                            this.licenseInfo.Address = info.certificate_address
                            this.contact_address = info.contact_address
                            this.contact_remark = info.contact_remark
                            this.userInfo.Name = info.corp_name
                            this.corp_id_type = info.corp_id_type
                            this.userInfo.IdNum = info.corp_id_number
                            this.userInfo.BeginDate = info.corp_id_begindate
                            this.userInfo.EndDate = info.corp_id_enddate
                            this.corp_phone = info.corp_phone
                            this.corp_phone_back = info.corp_phone_back
                            this.corp_email = info.corp_email
                            this.corp_remark = info.corp_remark
                            this.app_service = info.app_service
                            this.app_type1 = info.app_type1
                            this.app_type2 = info.app_type2
                            this.app_lang = info.app_lang
                            this.app_content_type = info.app_content_type
                            this.app_remark = info.app_remark
                            this.app_icp_number = info.app_icp_number
                            this.app_id = info.app_id
                            this.app_corp_name = info.app_corp_name
                            this.app_corp_id_type = info.app_corp_id_type
                            this.app_corp_id_number = info.app_corp_id_number
                            this.app_corp_id_begindate = info.app_corp_id_begindate
                            this.app_corp_id_enddate = info.app_corp_id_enddate
                            this.app_corp_phone = info.app_corp_phone
                            this.app_corp_phone_back = info.app_corp_phone_back
                            this.app_corp_email = info.app_corp_email

                            this.certificate_file = info.certificate_file
                            this.corp_file_front = info.corp_file_front
                            this.corp_file_back = info.corp_file_back
                            this.app_corp_file_front = info.app_corp_file_front
                            this.app_corp_file_back = info.app_corp_file_back
                            this.agreement1 = info.agreement1
                            this.agreement2 = info.agreement2
                            this.agreement3 = info.agreement3
                            this.agreement4 = info.agreement4
                            this.agreement5 = info.agreement5
                            this.remark = info.remark

                            // 有主办单位信息展开折叠
                            if (this.licenseInfo.RegNum !== null || this.licenseInfo.Name !== null || this.licenseInfo.Address !== null) {
                                this.isBiz = true
                            }

                            // 有用户信息展开折叠
                            if (
                                this.userInfo.Name !== null ||
                                this.userInfo.IdNum !== null ||
                                this.userInfo.BeginDate !== null ||
                                this.userInfo.EndDate !== null
                            ) {
                                this.isUser = true
                            }

                            // 有小程序负责人信息展开折叠
                            if (
                                this.app_corp_name !== null ||
                                this.app_corp_id_number !== null ||
                                this.app_corp_id_begindate !== null ||
                                this.app_corp_id_enddate !== null
                            ) {
                                this.isAppUser = true
                            }

                            // 如果省有数据获取市
                            if (this.province_name) {
                                this.$axios
                                    .get(`https://beian.idss-cn.com/api/areas/?action=getcities&province=${this.province_name}`)
                                    .then((res) => {
                                        this.cityHtml = res.data
                                    })
                            }

                            // 如果省和市都有数据获取区
                            if (this.province_name && this.city_name) {
                                this.$axios
                                    .get(
                                        `https://beian.idss-cn.com/api/areas/?action=getareas&province=${this.province_name}&city=${this.city_name}`
                                    )
                                    .then((res) => {
                                        this.areaHtml = res.data
                                    })
                            }
                            // 如果服务类型1有数据
                            if (this.app_type1) {
                                this.$axios
                                    .get(`https://beian.idss-cn.com/api/types/?action=gettype2&type1=${this.app_type1}`)
                                    .then((res) => {
                                        this.lastTypeHtml = res.data
                                    })
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },

        // 省改变
        provinceChange() {
            // 当省改变获取市
            ;(this.cityHtml = null),
                (this.areaHtml = null),
                this.$axios.get(`https://beian.idss-cn.com/api/areas/?action=getcities&province=${this.province_name}`).then((res) => {
                    this.cityHtml = res.data
                })
        },

        // 市改变
        cityChange() {
            // 当市改变获取区
            this.$axios
                .get(`https://beian.idss-cn.com/api/areas/?action=getareas&province=${this.province_name}&city=${this.city_name}`)
                .then((res) => {
                    this.areaHtml = res.data
                })
        },

        // 服务类型改变
        firstTypeChange() {
            // 当市改变获取区
            this.$axios.get(`https://beian.idss-cn.com/api/types/?action=gettype2&type1=${this.app_type1}`).then((res) => {
                this.lastTypeHtml = res.data
            })
        },

        // 验证号码格式
        checkPhoneNumber(val, dataName) {
            let reg_phobe = /^1[13456789]\d{9}$/
            if (!reg_phobe.test(val)) {
                // 验证失败，清空当前输入框的内容
                this[dataName] = ''
                alert('请输入有效的电话号码')
                return
            }
        },

        // 验证邮箱格式
        checkEmail(val, dataName) {
            let reg_email = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
            if (!reg_email.test(val)) {
                // 验证失败，清空当前输入框的内容
                this[dataName] = ''
                alert('请输入有效的邮箱地址')
                return
            }
        },

        // 图片上传
        fileUpload(file, index, type, isApp) {
            let _token = this.$cookies.get('cj_token')
            // console.log("当前文件base64：",file);
            this.$axios
                .post('/api/order/file', {
                    token: _token,
                    file: file,
                    type: type,
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.data) {
                        let path = res.data.data.path
                        // console.log('path', path)

                        // 赋值相应的文件框
                        if (index == 1) {
                            this.certificate_file = path
                        } else if (index == 2) {
                            this.corp_file_front = path
                        } else if (index == 3) {
                            this.corp_file_back = path
                        } else if (index == 4) {
                            this.app_corp_file_front = path
                        } else if (index == 5) {
                            this.app_corp_file_back = path
                        } else if (index == 6) {
                            this.agreement1 = path
                        } else if (index == 7) {
                            this.agreement2 = path
                        } else if (index == 8) {
                            this.agreement3 = path
                        } else if (index == 9) {
                            this.agreement4 = path
                        } else {
                            this.agreement5 = path
                        }

                        // 营业执照
                        if (type == 'BizLicense') {
                            this.isBiz = true

                            this.licenseInfo = JSON.parse(res.data.data.data)
                            // console.log('licenseInfo', this.licenseInfo)
                            if (this.licenseInfo.Person) {
                                this.userInfo.Name = this.licenseInfo.Person
                            }
                        }

                        // 身份证
                        if (type == 'IDCard' && !isApp) {
                            this.isUser = true

                            let user = JSON.parse(res.data.data.data)
                            if (user.Address) {
                                this.userInfo.Address = user.Address
                            }
                            if (user.AdvancedInfo) {
                                this.userInfo.AdvancedInfo = user.AdvancedInfo
                            }
                            if (user.Authority) {
                                this.userInfo.Authority = user.Authority
                            }
                            if (user.Birth) {
                                this.userInfo.Birth = user.Birth
                            }
                            if (user.IdNum) {
                                this.userInfo.IdNum = user.IdNum
                            }
                            if (user.Name) {
                                this.userInfo.Name = user.Name
                            }
                            if (user.Nation) {
                                this.userInfo.Nation = user.Nation
                            }
                            if (user.RequestId) {
                                this.userInfo.RequestId = user.RequestId
                            }
                            if (user.Sex) {
                                this.userInfo.Sex = user.Sex
                            }
                            if (user.ValidDate) {
                                let ValidDate = user.ValidDate
                                let parts = ValidDate.split('-')
                                let part1 = parts[0].replace(/\./g, '') // 证件有效期起始日期
                                let part2 = parts[1].replace(/\./g, '') // 证件有效期终止日期
                                this.userInfo.BeginDate = part1
                                this.userInfo.EndDate = part2
                            }
                            // console.log('user', user)
                            // console.log('userInfo', this.userInfo)
                        }

                        // 小程序上传信息
                        if (type == 'IDCard' && isApp == 'app') {
                            this.isAppUser = true

                            let user = JSON.parse(res.data.data.data)
                            if (user.Name) {
                                this.app_corp_name = user.Name
                            }
                            if (user.IdNum) {
                                this.app_corp_id_number = user.IdNum
                            }
                            if (user.ValidDate) {
                                let ValidDate = user.ValidDate
                                let parts = ValidDate.split('-')
                                let part1 = parts[0].replace(/\./g, '') // 证件有效期起始日期
                                let part2 = parts[1].replace(/\./g, '') // 证件有效期终止日期
                                this.app_corp_id_begindate = part1
                                this.app_corp_id_enddate = part2
                            }

                            // console.log('appUser', user)
                        }
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },

        // 不同类型的文件上传
        handleFileUpload(index) {
            // 对应的input文件框
            let fileInput = this.$refs[`fileInput${index}`]
            //获取内容
            let file = fileInput.files[0]
            let reader = new FileReader()

            // 图片大小不能超过500kb
            if (file.size > 500 * 1024) {
                fileInput.value = '' // 清空输入框中的内容
                alert('图片大小不能超过 500KB，请重新上传')
                return
            }

            reader.onload = () => {
                // 这里的 base64String 就是图片的base64编码信息
                let base64String = reader.result.split(',')[1]

                if (index == 1) {
                    this.fileUpload(base64String, 1, 'BizLicense')
                } else if (index == 2) {
                    this.fileUpload(base64String, 2, 'IDCard')
                } else if (index == 3) {
                    this.fileUpload(base64String, 3, 'IDCard')
                } else if (index == 4) {
                    this.fileUpload(base64String, 4, 'IDCard', 'app')
                } else if (index == 5) {
                    this.fileUpload(base64String, 5, 'IDCard', 'app')
                } else if (index == 6) {
                    this.fileUpload(base64String, 6)
                } else if (index == 7) {
                    this.fileUpload(base64String, 7)
                } else if (index == 8) {
                    this.fileUpload(base64String, 8)
                } else if (index == 9) {
                    this.fileUpload(base64String, 9)
                } else {
                    this.fileUpload(base64String)
                }
            }

            reader.readAsDataURL(file)
        },

        // 检查是否有空的证件上传input框
        checkEmptyInput() {
            this.$nextTick(() => {
                this.inputs = this.$el.querySelectorAll('.verify_input')
                // console.log('this.inputs', this.inputs)
            })

            if (this.certificate_file === null) {
                return this.uploadInp[0]
            } else if (this.corp_file_front === null) {
                return this.uploadInp[1]
            } else if (this.corp_file_back === null) {
                return this.uploadInp[2]
            } else if (this.app_corp_file_front === null) {
                return this.uploadInp[3]
            } else if (this.app_corp_file_back === null) {
                return this.uploadInp[4]
            } else if (this.agreement1 === null) {
                return this.uploadInp[5]
            } else if (this.agreement2 === null) {
                return this.uploadInp[6]
            }

            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputs.length; i++) {
                if (this.inputs[i].value === '') {
                    return this.inputs[i]
                }
            }

            return null // 如果没有空的输入框
        },

        // 已填写信息暂存
        saveInfo() {
            // console.log('信息暂存')
            // 提交表单逻辑
            this.fromClass = false //清除bs表单的提醒样式
            // 提交订单信息
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/draft', {
                    token: _token,
                    order_id: parseInt(this.orderId),
                    province_name: this.province_name,
                    city_name: this.city_name,
                    area_name: this.area_name,
                    certificate_type: this.certificate_type,
                    certificate_number: this.licenseInfo.RegNum,
                    icp_number: this.icp_number,
                    certificate_address: this.licenseInfo.Address,
                    contact_address: this.contact_address,
                    contact_remark: this.contact_remark,
                    corp_name: this.userInfo.Name,
                    corp_id_type: this.corp_id_type,
                    corp_id_number: this.userInfo.IdNum,
                    corp_id_begindate: this.userInfo.BeginDate,
                    corp_id_enddate: this.userInfo.EndDate,
                    corp_phone: this.corp_phone,
                    corp_phone_back: this.corp_phone_back,
                    corp_email: this.corp_email,
                    corp_remark: this.corp_remark,
                    app_service: this.app_service,
                    app_type1: this.app_type1,
                    app_type2: this.app_type2,
                    app_lang: this.app_lang,
                    app_content_type: this.app_content_type,
                    app_remark: this.app_remark,
                    app_icp_number: this.app_icp_number,
                    app_id: this.app_id,
                    app_corp_name: this.app_corp_name,
                    app_corp_id_type: this.app_corp_id_type,
                    app_corp_id_number: this.app_corp_id_number,
                    app_corp_id_begindate: this.app_corp_id_begindate,
                    app_corp_id_enddate: this.app_corp_id_enddate,
                    app_corp_phone: this.app_corp_phone,
                    app_corp_phone_back: this.app_corp_phone_back,
                    app_corp_email: this.app_corp_email,

                    certificate_file: this.certificate_file,
                    corp_file_front: this.corp_file_front,
                    corp_file_back: this.corp_file_back,
                    app_corp_file_front: this.app_corp_file_front,
                    app_corp_file_back: this.app_corp_file_back,
                    agreement1: this.agreement1,
                    agreement2: this.agreement2,
                    agreement3: this.agreement3,
                    agreement4: this.agreement4,
                    agreement5: this.agreement5,
                    remark: this.remark,
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.code == 200) {
                        // console.log(res.data.msg)
                        this.$router.push({ path: '/myorder' })
                        return
                    }
                    if (res.data.code == 500) {
                        // console.log(res.data.msg)
                        this.errorData = res.data.msg
                    }
                })
                .catch((err) => {
                    // console.log('err', err)
                })
        },

        // 处理表单提交的逻辑
        submitInfo() {
            // console.log('提交表单')
            // 调用检查表单的函数
            let emptyInput = this.checkEmptyInput()

            if (emptyInput) {
                this.fromClass = true //给bs表单加上提醒样式的类名
                emptyInput.scrollIntoView({
                    behavior: 'smooth', //平移
                    block: 'center', //居中显示
                })
            } else {
                // 下一步操作
                // 提交表单逻辑
                this.fromClass = false //清除bs表单的提醒样式
                // 提交订单信息
                let _token = this.$cookies.get('cj_token')

                this.$axios
                    .post('/api/order/fill', {
                        token: _token,
                        order_id: parseInt(this.orderId),
                        province_name: this.province_name,
                        city_name: this.city_name,
                        area_name: this.area_name,
                        certificate_type: this.certificate_type,
                        certificate_number: this.licenseInfo.RegNum,
                        icp_number: this.icp_number,
                        certificate_address: this.licenseInfo.Address,
                        contact_address: this.contact_address,
                        contact_remark: this.contact_remark,
                        corp_name: this.userInfo.Name,
                        corp_id_type: this.corp_id_type,
                        corp_id_number: this.userInfo.IdNum,
                        corp_id_begindate: this.userInfo.BeginDate,
                        corp_id_enddate: this.userInfo.EndDate,
                        corp_phone: this.corp_phone,
                        corp_phone_back: this.corp_phone_back,
                        corp_email: this.corp_email,
                        corp_remark: this.corp_remark,
                        app_service: this.app_service,
                        app_type1: this.app_type1,
                        app_type2: this.app_type2,
                        app_lang: this.app_lang,
                        app_content_type: this.app_content_type,
                        app_remark: this.app_remark,
                        app_icp_number: this.app_icp_number,
                        app_id: this.app_id,
                        app_corp_name: this.app_corp_name,
                        app_corp_id_type: this.app_corp_id_type,
                        app_corp_id_number: this.app_corp_id_number,
                        app_corp_id_begindate: this.app_corp_id_begindate,
                        app_corp_id_enddate: this.app_corp_id_enddate,
                        app_corp_phone: this.app_corp_phone,
                        app_corp_phone_back: this.app_corp_phone_back,
                        app_corp_email: this.app_corp_email,

                        certificate_file: this.certificate_file,
                        corp_file_front: this.corp_file_front,
                        corp_file_back: this.corp_file_back,
                        app_corp_file_front: this.app_corp_file_front,
                        app_corp_file_back: this.app_corp_file_back,
                        agreement1: this.agreement1,
                        agreement2: this.agreement2,
                        agreement3: this.agreement3,
                        agreement4: this.agreement4,
                        agreement5: this.agreement5,
                        remark: this.remark,
                    })
                    .then((res) => {
                        console.log('res', res)
                        if (res.data.code == 200) {
                            // console.log(res.data.msg)
                            this.isModel = true
                            return
                        }
                        if (res.data.code == 500) {
                            console.log(res.data.msg)
                            this.showErrorMessage()
                            // let firstEntry = Object.entries(res.data.data)
                            // console.log('firstEntry', firstEntry)
                            // this.firstEntryArray = firstEntry
                            // if (firstEntry) {
                            //     let [firstKey, firstValue] = firstEntry
                            //     console.log(firstKey, firstValue)
                            //     this.errorData = firstValue
                            //     console.log(this.errorData,'this.errorData');
                            // }
                            return
                        }
                    })
                    .catch((err) => {
                        console.log('err', err)
                    })
            }
        },

        // 页面跳转
        goPage(path, i) {
            this.isModel = false
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: this.orderId } })
            } else {
                this.$router.push(`/${path}`)
            }
        },

        // 折叠
        fold(area) {
            if (area == 'else') {
                this.elseArea = !this.elseArea
            }
        },

        // 错误弹框
        showErrorMessage() {
            // 显示错误弹框
            this.isError = true

            // 三秒后隐藏错误弹框
            setTimeout(() => {
                this.isError = false
            }, 3000)
        },
    },
    mounted() {
        // this.inputs = document.querySelectorAll('.verify_input')
        this.uploadInp = document.querySelectorAll('.upload-inp')

        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        // console.log('当前订单id为：', this.orderId)

        let user = localStorage.getItem('cj_user')
        this.user = user

        // 执行获取详情
        this.getDetails()

        //获取省
        this.$axios.get('https://beian.idss-cn.com/api/areas/?action=getprovinces').then((res) => {
            this.provinceHtml = res.data
        })

        // //获取服务类型
        this.$axios.get('https://beian.idss-cn.com/api/types/?action=gettype1').then((res) => {
            this.firstTypeHtml = res.data
        })
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;
$assist-color: #47958f;

.bg-box {
    background-color: #f7fbfa;
    min-height: 100%;
}

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }

    .crumb-box {
        display: flex;

        .crumb {
            height: 26px;
            line-height: 26px;
            padding: 0px 12px 0 24px;
            font-size: 14px;
            color: #a1a1a1;
            margin-right: 14px;
            position: relative;
            background-color: #e3e3e3;

            &::before,
            &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 12px solid transparent;
            }

            &::before {
                left: 0;
                top: 0;
                border-left-color: #fff;
            }

            &::after {
                right: -24px;
                top: 0;
                border-left-color: #e3e3e3;
            }

            &:first-child {
                padding-left: 12px;

                &::before {
                    content: none;
                }
            }

            &:last-child {
                margin-right: 0;
                &::after {
                    content: none;
                }
            }
        }

        .active {
            background-color: $primary-color;
            color: #fff;

            &::after {
                border-left-color: $primary-color;
            }
        }
    }
}

// 取消bs自带的验证框样式
.was-validated {
    input:focus,
    select:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.inp {
    border: none;
    padding: 0;
    font-size: 14px;

    &::placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.add_inp {
    border: 1px solid #f1f1f1;
    padding: 0 14px;
    height: 38px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 0;
    width: 360px;
    flex-shrink: 0;

    &.wd-1 {
        width: 336px;
    }

    &::placeholder {
        color: #b4b4b4;
        font-size: 12px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: $primary-color;
    }

    &:-webkit-autofill {
        background: transparent;
        transition: background-color 50000s ease-in-out 0s;
        -webkit-text-fill-color: unset;
    }
}

// 未上传警告
.warning-text {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}

// 区域标题
.area-title {
    font-size: 16px;
    font-weight: bold;
    padding: 24px 0 10px;
}

// 订单信息
.order-info {
    .info-list {
        padding: 30px 34px;

        .list-box {
            display: flex;
            margin-bottom: 17px;

            &:last-child {
                margin-bottom: 0;
            }

            .list {
                flex-basis: 30%;
                display: flex;
                align-items: center;
                margin-right: 60px;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                .list-title {
                    font-size: 14px;
                    font-weight: bold;
                    flex-basis: 30%;

                    &.fb-two {
                        flex-basis: 25%;
                    }
                    &.fb-three {
                        flex-basis: 20%;
                    }
                }

                .list-content {
                    height: 38px;
                    border: 1px solid #f1f1f1;
                    padding: 0 16px;
                    flex-grow: 1;
                    box-sizing: border-box;
                    overflow: hidden;

                    .content {
                        font-size: 14px;
                        line-height: 38px;
                    }

                    input {
                        height: 100%;
                    }
                }
            }
        }
    }
}

// 主办单位信息
.sponsor-info {
    .id-photo {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        padding: 26px 34px 20px;

        .upload-box {
            display: flex;

            .upload,
            .example {
                .title {
                    text-align: center;
                    margin-top: 6px;
                    font-size: 14px;
                }
            }

            .upload {
                margin-right: 18px;

                .file-box {
                    width: 292px;
                    height: 186px;
                    background-color: #f9f9f9;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .file-inp {
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        position: relative;
                        z-index: 9;
                    }

                    .upload-img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        padding: 16px;
                        box-sizing: border-box;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .example {
                .img-box {
                    width: 292px;
                    height: 186px;
                    background-color: #f9f9f9;
                    border-radius: 10px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                }
            }
        }

        .type-id {
            display: flex;
            align-items: center;
            margin-left: 42px;

            .title {
                flex-shrink: 0;
                margin-right: 12px;
                font-size: 14px;
                font-weight: bold;
            }

            select {
                height: 38px;
                border: 1px solid #f1f1f1;
                padding: 0 32px 0 16px;
                box-sizing: border-box;
            }
        }
    }
}

// 信息区域
.info-box {
    padding: 34px 0 34px 34px;

    &.pr-1 {
        padding-right: 154px;
    }

    &.pr-2 {
        padding-right: 80px;
    }

    &.pr-3 {
        padding-right: 90px;
    }

    .list {
        display: flex;
        margin-bottom: 18px;

        > .info-list {
            display: flex;
            align-items: center;
            flex-basis: 50%;

            label {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: bold;
                flex-shrink: 0;

                &.fx-1 {
                    flex-basis: 72px;
                }

                &.fx-2 {
                    flex-basis: 146px;
                }

                &.fx-3 {
                    flex-basis: 144px;
                }

                &.fx-4 {
                    flex-basis: 114px;
                }

                &.fx-5 {
                    flex-basis: 178px;
                }

                &.fx-6 {
                    flex-basis: 158px;
                }
            }

            // 地域选择
            .area-select,
            .sever-box {
                display: flex;
                width: 360px;
                flex-shrink: 0;

                > div {
                    flex: 1;
                }

                select {
                    border: 1px solid #f1f1f1;
                    padding: 0 14px;
                    height: 38px;
                    font-size: 14px;
                    box-sizing: border-box;
                    border-radius: 0;

                    &::placeholder {
                        color: #b4b4b4;
                        font-size: 12px;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border-color: $primary-color;
                    }

                    &:-webkit-autofill {
                        background: transparent;
                        transition: background-color 50000s ease-in-out 0s;
                        -webkit-text-fill-color: unset;
                    }
                }
            }
        }
    }

    > .info-list {
        display: flex;
        align-items: center;

        &.md-1 {
            margin-bottom: 18px;
        }

        label {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: bold;
            flex-shrink: 0;

            &.fx-1 {
                flex-basis: 72px;
            }

            &.fx-2 {
                flex-basis: 146px;
            }

            &.fx-3 {
                flex-basis: 144px;
            }

            &.fx-4 {
                flex-basis: 114px;
            }

            &.fx-5 {
                flex-basis: 178px;
            }

            &.fx-6 {
                flex-basis: 158px;
            }
        }

        input {
            flex-grow: 1;
        }
    }
}

// 有效日期
.date-box {
    width: 360px;

    &.wd-1 {
        width: 336px;
    }

    input {
        border: 1px solid #f1f1f1;
        padding: 0 14px;
        height: 38px;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 0;

        &::placeholder {
            color: #b4b4b4;
            font-size: 12px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: $primary-color;
        }

        &:-webkit-autofill {
            background: transparent;
            transition: background-color 50000s ease-in-out 0s;
            -webkit-text-fill-color: unset;
        }
    }

    .icon {
        width: 20px;
        height: 2px;
        background-color: #b9b9b9;
        margin: 0 8px;
    }
}

// 提示
.tips {
    display: flex;
    margin-top: 20px;

    &.ma-1 {
        margin-top: 38px;
    }

    .tips-title {
        font-size: 14px;
        font-weight: bold;
        margin-right: 8px;
        flex-shrink: 0;
    }

    .text {
        font-size: 12px;
        color: #727272;
        line-height: 22px;

        span {
            color: #ea0000;
        }
    }
}

// 身份证上传
.upload-area {
    display: flex;
    margin-bottom: 12px;
    padding: 30px 34px;

    .upload {
        width: 292px;

        &:first-child {
            margin-right: 18px;
        }

        .file-box {
            background-color: #f9f9f9;
            border-radius: 10px;
            overflow: hidden;
            height: 208px;
            position: relative;

            .icon-box1 {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                &::before,
                &::after {
                    position: absolute;
                    top: 22px;
                    width: 24px;
                    height: 24px;
                    content: '';
                    border-top: 3px solid $primary-color;
                }

                &::before {
                    left: 22px;
                    border-left: 3px solid $primary-color;
                }
                &::after {
                    right: 22px;
                    border-right: 3px solid $primary-color;
                }

                .icon-box2 {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;

                    &::before,
                    &::after {
                        position: absolute;
                        bottom: 22px;
                        width: 24px;
                        height: 24px;
                        content: '';
                        border-bottom: 3px solid $primary-color;
                    }

                    &::before {
                        left: 22px;
                        border-left: 3px solid $primary-color;
                    }
                    &::after {
                        right: 22px;
                        border-right: 3px solid $primary-color;
                    }
                }
            }

            .icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .file-inp {
                width: 100%;
                height: 100%;
                opacity: 0;
                position: relative;
                z-index: 9;
            }

            .upload-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 16px;
                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 12px;
            font-size: 14px;

            span {
                color: $primary-color;
            }
        }
    }
}

// 其他材料上传
.other-material {
    .material-box {
        padding: 34px;

        .else-cl {
            .title-box {
                display: flex;
                align-items: center;
                padding: 26px 0 16px;
                cursor: pointer;
                user-select: none;

                .else-title {
                    font-size: 14px;
                }

                .fold-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .material-list {
            display: flex;
            flex-wrap: wrap;

            &.msh-1 {
                margin-top: 20px;
            }

            .list-item {
                margin-right: 52px;
                margin-bottom: 30px;

                &:nth-last-child(-n + 3) {
                    margin-bottom: 0;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                .list-head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px;
                    margin-top: 8px;
                }

                .list-content {
                    width: 330px;
                    height: 200px;
                    background-color: #f9f9f9;
                    border-radius: 10px;
                    position: relative;

                    .file-inp {
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        position: relative;
                        z-index: 9;
                    }

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .upload-img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        padding: 16px;
                        box-sizing: border-box;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

// 备注信息
.postscript {
    .c-box {
        padding: 14px 34px;

        textarea {
            resize: none;
            background: #f9f9f9;
            border: none;
            font-size: 14px;
            width: 100%;
            border-radius: 5px;
            height: 54px;
            padding: 16px 20px;
            box-sizing: border-box;

            &::placeholder {
                font-size: 12px;
                color: #aaaaaa;
            }
        }
    }
}

// 操作按钮
.reviewing {
    padding: 66px 0 78px;

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 170px;
            height: 52px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 6px;
            color: #fff;
        }

        .draft-btn {
            margin-right: 8px;
            background-color: #acacac;
            border: none;
        }
    }
}

// 提交后模态框
.mark_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 568px;
        height: 356px;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            margin: 12px 0 46px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 130px;
                height: 40px;
                font-size: 14px;
                letter-spacing: 1px;
                border-radius: 20px;
                padding: 6px 0px;
                color: #fff;
                box-sizing: border-box;
                user-select: none;
            }

            .go_btn {
                background-color: $assist-color;
                border-color: $assist-color;
            }

            .go_affirm {
                background-color: #fff;
                margin-left: 16px;
                border: 1px solid #c4c4c4;
                color: #535353;
            }
        }
    }
}

.error_mark {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .error_box {
        display: flex;
        align-items: center;
        background-color: #fff;
        // border: 1px solid #d3d3d3;
        padding: 20px 30px;
        box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.3);

        img {
            margin-right: 20px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>