<template>
    <div class="home">
        <!-- banner -->
        <div class="banner">
            <div class="w-box">
                <div class="info">
                    <div class="banner-title">小程序备案服务</div>
                    <div class="vice-title">在中国大陆境内，开发和发布小程序之前，都应依法进行备案申请，仓颉科技为您提供高效便捷的 备案服务，以确保小程序的合法性、合规性和规范性。</div>
                    <div class="btn-box">
                        <div class="beian-btn" @click="goPage('myOrder')">开始备案</div>
                        <div class="consult-btn">免费咨询</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 流程 -->
        <div class="beian-flow">
            <div class="w-box">
                <div class="area-title">
                    <div class="title">备案流程</div>
                    <div class="vice-title">一站式专人服务·全程跟进·省时省力</div>
                </div>
                <div class="flow-list">
                    <div class="flow" v-for="item,index in flowArray" :key="index">
                        <div class="img-box">
                            <img :src="require(`@/assets/img/home/icon${index + 1}.png`)" alt />
                        </div>
                        <div class="flow-title">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 备案服务 -->
        <div class="beian-serve">
            <div class="area-title">
                <div class="title">备案服务</div>
                <div class="vice-title">一站式专人服务·全程跟进·省时省力</div>
            </div>
            <div class="serve-bg">
                <div class="serve-list w-box">
                    <div class="serve" v-for="item,index in goods" :key="item.id">
                        <div class="head-title">
                            <div class="title">{{item.name}}</div>
                            <div class="vice-title" v-if="index == 0">
                                <span>/</span>指导用户填写备案材料
                            </div>
                            <div class="vice-title" v-if="index == 1">
                                <span>/</span>1v1咨询，极速提交
                            </div>
                            <div class="ps-title" v-if="index == 1">99%用户选择</div>
                        </div>
                        <div class="content" v-if="index == 0">
                            <div class="rules-list">
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">按照小程序备案要求，指导填写有关备案材料并实名核验;</div>
                                </div>
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">开展材料完备性、规范性、真实性审核;</div>
                                </div>
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">审核通过后经小程序对应分发平台向小程序主办者住所所在地通信管理局提交备案申请。</div>
                                </div>
                            </div>
                        </div>
                        <div class="content" v-if="index == 1">
                            <div class="rules-list">
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">按照小程序备案要求，指导进行小程序的注册申请，填写和提交小程序名称、服务内容、主体负责人信息等材料;</div>
                                </div>
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">反馈注册审核结果;</div>
                                </div>
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">按照小程序备案要求，指导填写有关备案材料并实名核验;</div>
                                </div>
                                <div class="rules">
                                    <img src="@/assets/img/home/polygon.png" alt />
                                    <div class="text">开展材料完备性、规范性、真实性审核;</div>
                                </div>
                            </div>
                        </div>
                        <div class="serve-bottom">
                            <div class="price">
                                ￥
                                <span>{{(item.price * 100) / 100}}</span>/次
                            </div>
                            <div class="btn-box">
                                <div class="beian-btn" @click="goPage('createOrder',index)">立即办理</div>
                                <div class="consult-btn">免费咨询</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 备案材料 -->
        <div class="beian-materials">
            <div class="w-box">
                <div class="area-title">
                    <div class="title">备案材料准备</div>
                    <div class="vice-title">一站式专人服务·全程跟进·省时省力</div>
                </div>
                <div class="materials-area">
                    <img src="@/assets/img/home/bg-icon.png" class="bg-icon" />
                    <div class="title">备案材料清单</div>
                    <div class="materials-list">
                        <div>
                            <div class="top">1</div>
                            <div class="text">主办单位证件:营业执照/民办非企业单位登记证书等形式主体证件;</div>
                        </div>
                        <div>
                            <div class="top">2</div>
                            <div class="text">主体负责人证件:法人身份证信息;</div>
                        </div>
                        <div>
                            <div class="top">3</div>
                            <div class="text">小程序负责人证件:管理员身份证信息、联系方式;</div>
                        </div>
                        <div>
                            <div class="top">4</div>
                            <div class="text">前置审批/专项审批:如从事新闻、出版、药品和医疗器械等需须经有关主管部门审核同意的互联网信息服务，在履行备案手续时，应提供业务对应前置审批文件;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 更多服务 -->
        <div class="more-serve">
            <div class="w-box">
                <div class="area-title">
                    <div class="title">更多服务</div>
                    <div class="vice-title">一站式专人服务·全程跟进·省时省力</div>
                </div>
                <div class="list">
                    <div>
                        <div class="top-box">
                            <div class="icon-box">
                                <img src="@/assets/img/home/cx-1.png" class="ps-icon front" alt />
                                <img src="@/assets/img/home/cx-2.png" class="ps-icon back" alt />
                            </div>
                            <div class="title">小程序定制</div>
                        </div>
                        <div class="series">
                            <div class="text">电商零售</div>
                            <div class="text">餐饮外卖</div>
                            <div class="text">本地活动/营销活动</div>
                            <div class="text">···</div>
                        </div>
                        <div class="more-btn">更多定制咨询</div>
                    </div>
                    <div>
                        <div class="top-box">
                            <div class="icon-box">
                                <img src="@/assets/img/home/wz-1.png" class="ps-icon front" alt />
                                <img src="@/assets/img/home/wz-2.png" class="ps-icon back" alt />
                            </div>
                            <div class="title">网站定制</div>
                        </div>
                        <div class="series">
                            <div class="text">电商零售</div>
                            <div class="text">餐饮外卖</div>
                            <div class="text">本地活动/营销活动</div>
                            <div class="text">···</div>
                        </div>
                        <div class="more-btn">更多定制咨询</div>
                    </div>
                    <div>
                        <div class="top-box">
                            <div class="icon-box">
                                <img src="@/assets/img/home/wx-1.png" class="ps-icon front" alt />
                                <img src="@/assets/img/home/wx-2.png" class="ps-icon back" alt />
                            </div>
                            <div class="title">微信公众号定制</div>
                        </div>
                        <div class="series">
                            <div class="text">电商零售</div>
                            <div class="text">餐饮外卖</div>
                            <div class="text">本地活动/营销活动</div>
                            <div class="text">···</div>
                        </div>
                        <div class="more-btn">更多定制咨询</div>
                    </div>
                    <div>
                        <div class="top-box">
                            <div class="icon-box">
                                <img src="@/assets/img/home/app-1.png" class="ps-icon front" alt />
                                <img src="@/assets/img/home/app-2.png" class="ps-icon back" alt />
                            </div>
                            <div class="title">APP定制</div>
                        </div>
                        <div class="series">
                            <div class="text">电商零售</div>
                            <div class="text">餐饮外卖</div>
                            <div class="text">本地活动/营销活动</div>
                            <div class="text">···</div>
                        </div>
                        <div class="more-btn">更多定制咨询</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            flowArray: [
                {
                    title: '填写备案信息',
                },
                {
                    title: '验证备案类型',
                },
                {
                    title: '平台初审',
                },
                {
                    title: '工信部短信核验',
                },
                {
                    title: '通管局审核',
                },
                {
                    title: '备案成功',
                },
            ],
            goods: [], //商品列表

            ifLogin: false, //是否登录
        }
    },
    methods: {
        begin() {
            // 获取cookie里的token
            const _token = this.$cookies.get('cj_token')
            //判断是否存在登录信息
            if (_token) {
                // 说明用户已登录
                this.ifLogin = true
                this.$store.commit('setLoginStatus', true)
                this.$store.commit('setToken', _token)
            } else {
                this.ifLogin = false
            }
        },

        // 页面跳转
        goPage(path, i) {
            // 调用检查登录
            this.begin()
            
            if (this.ifLogin) {
                if (i !== null) {
                    this.$router.push({ path: `/${path}`, query: { id: i } })
                } else {
                    this.$router.push(`/${path}`)
                }
            } else {
                // 用户未登录，重定向至登录页
                this.$router.push('/login')
            }
        },
    },
    created() {
        this.$axios.get('/api/goods/list').then((res) => {
            // console.log('res', res)
            this.goods = res.data.data
        })
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;

.home {
    .banner {
        height: 482px;
        background: url(@/assets/img/home/banner.png) no-repeat;

        > .w-box {
            height: 100%;
        }

        .info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .banner-title {
                font-size: 38px;
            }

            .vice-title {
                width: 590px;
                font-size: 16px;
                color: #302f2c;
                line-height: 24px;
                margin: 22px 0 70px;
            }

            .btn-box {
                display: flex;
                align-items: center;

                .beian-btn,
                .consult-btn {
                    width: 130px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    border-radius: 5px;
                    font-size: 14px;
                    cursor: pointer;
                    user-select: none;
                }
                .beian-btn {
                    background-color: $primary-color;
                    color: #fff;
                    margin-right: 14px;
                }
                .consult-btn {
                    background-color: #fff;
                    color: $primary-color;
                }
            }
        }
    }

    .area-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 30px;
            margin-bottom: 4px;
        }
        .vice-title {
            font-size: 12px;
            color: #848484;
        }
    }

    .beian-flow {
        margin: 60px 0 80px;

        .flow-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 46px;
            margin-top: 28px;

            .flow {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                &::after {
                    position: absolute;
                    width: 68px;
                    height: 1px;
                    border-bottom: 1px dashed $primary-color;
                    content: '';
                    right: -98px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }

                .img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 18px;
                }

                .flow-title {
                    font-size: 14px;
                    color: #414141;
                }
            }
        }
    }

    // 备案服务
    .beian-serve {
        .serve-bg {
            height: 460px;
            background: url(@/assets/img/home/serve-bg.png) no-repeat;
            margin-top: 26px;

            .serve-list {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;

                .serve {
                    width: 574px;
                    background-color: #fff;
                    border-radius: 10px;
                    overflow: hidden;

                    .head-title {
                        background-color: #e6f4f4;
                        display: flex;
                        align-items: flex-end;
                        position: relative;
                        padding: 19px 0 12px 33px;

                        .title {
                            font-size: 20px;
                            color: $primary-color;
                            font-weight: bold;
                        }

                        .vice-title {
                            color: #519b95;
                            font-size: 14px;

                            span {
                                margin: 0 8px;
                            }
                        }

                        .ps-title {
                            position: absolute;
                            right: 0;
                            top: 0;
                            background-color: #51d4cb;
                            font-size: 14px;
                            color: #fff;
                            padding: 6px 10px;
                            border-radius: 0 10px 0 10px;
                        }
                    }

                    .content {
                        .rules-list {
                            min-height: 200px;
                            padding: 28px 36px 20px;

                            .rules {
                                display: flex;
                                align-items: flex-start;
                                margin-bottom: 12px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                img {
                                    margin-top: 5px;
                                    margin-right: 11px;
                                }

                                .text {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .serve-bottom {
                        border-top: 1px solid #f5f5f5;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 22px 26px 22px 35px;

                        .price {
                            display: flex;
                            align-items: flex-end;
                            font-size: 14px;
                            color: $primary-color;
                            font-weight: bold;

                            span {
                                font-size: 18px;
                                margin-bottom: -1px;
                            }
                        }
                        .btn-box {
                            display: flex;
                            align-items: center;

                            .beian-btn,
                            .consult-btn {
                                width: 124px;
                                height: 36px;
                                line-height: 36px;
                                text-align: center;
                                border-radius: 5px;
                                font-size: 14px;
                                cursor: pointer;
                                user-select: none;
                            }
                            .beian-btn {
                                background-color: $primary-color;
                                color: #fff;
                                margin-right: 9px;
                            }
                            .consult-btn {
                                background-color: #fff;
                                color: $primary-color;
                                border: 1px solid $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // 备案材料
    .beian-materials {
        margin: 50px 0;

        .materials-area {
            background-color: #f7fdfc;
            position: relative;
            height: 390px;
            padding-left: 60px;
            padding-top: 48px;
            margin-top: 26px;

            .bg-icon {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .title {
                position: relative;
                z-index: 1;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .materials-list {
                position: relative;
                z-index: 1;

                > div {
                    display: flex;
                    margin-bottom: 32px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .top {
                        width: 22px;
                        height: 22px;
                        line-height: 22px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: #e1f6f0;
                        font-size: 12px;
                        color: $primary-color;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    .text {
                        font-size: 14px;
                        width: 516px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    // 更多服务
    .more-serve {
        padding-bottom: 82px;

        .list {
            display: flex;
            justify-content: space-between;
            padding: 0 70px;
            margin-top: 28px;

            > div {
                width: 225px;
                height: 313px;
                background-color: #fff;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                user-select: none;
                transition-duration: 0.4s;

                &:hover {
                    background-color: $primary-color;
                    box-shadow: none;

                    .top-box {
                        .icon-box {
                            .front {
                                display: none;
                            }
                            .back {
                                display: block;
                            }
                        }

                        .title {
                            color: #fff;
                        }
                    }

                    .series {
                        .text {
                            color: #d7ede9;
                        }
                    }

                    .more-btn {
                        border-color: #d7ede9;
                        color: #d7ede9;
                    }
                }

                .top-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .icon-box {
                        position: relative;
                        width: 52px;
                        height: 52px;
                        overflow: hidden;

                        .ps-icon {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        .front {
                            display: block;
                        }

                        .back {
                            display: none;
                        }
                    }
                    .title {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }

                .series {
                    margin: 16px 0 10px;

                    .text {
                        font-size: 12px;
                        color: #868686;
                        text-align: center;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .more-btn {
                    width: 116px;
                    height: 37px;
                    line-height: 37px;
                    text-align: center;
                    border-radius: 3px;
                    border: 1px solid #8d8d8d;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>