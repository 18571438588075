<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">订单详情</div>
            </div>
        </div>

        <div class="w-box">
            <!-- 信息区域 -->
            <div class="info-area">
                <div class="area-title">主体信息</div>
                <div class="list-area bgf">
                    <div class="info-list">
                        <div class="list">
                            <div class="title">证件类型</div>
                            <div class="content">{{detailsInfo.certificate_type}}</div>
                        </div>
                        <div class="list">
                            <div class="title">证件编号</div>
                            <div class="content">{{detailsInfo.certificate_number}}</div>
                        </div>
                        <div class="list">
                            <div class="title">备案号</div>
                            <div class="content">{{detailsInfo.icp_number}}</div>
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="list">
                            <div class="title">所属区域</div>
                            <div class="content">{{detailsInfo.province_name + detailsInfo.city_name + detailsInfo.area_name}}</div>
                        </div>
                        <div class="list">
                            <div class="title">证件住所</div>
                            <div class="content">{{detailsInfo.certificate_address}}</div>
                        </div>
                        <div class="list">
                            <div class="title">法定代表人姓名</div>
                            <div class="content">{{detailsInfo.corp_name}}</div>
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="list">
                            <div class="title">法定代表人证件号码</div>
                            <div class="content">{{detailsInfo.corp_id_number}}</div>
                        </div>
                        <div class="list">
                            <div class="title">通讯地址</div>
                            <div class="content">{{detailsInfo.contact_address}}</div>
                        </div>
                        <div class="list">
                            <div class="title">通讯地址其他备注</div>
                            <div class="content">{{detailsInfo.contact_remark}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-area">
                <div class="area-title">{{details.app_name}}小程序信息</div>
                <div class="list-area bgf">
                    <div class="info-list">
                        <div class="list">
                            <div class="title">小程序服务内容</div>
                            <div class="content">{{detailsInfo.app_service}}</div>
                        </div>
                        <div class="list">
                            <div class="title">服务类型</div>
                            <div class="content">{{detailsInfo.app_type1 + detailsInfo.app_type2}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序语言</div>
                            <div class="content">{{detailsInfo.app_lang}}</div>
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="list">
                            <div class="title">前置或专项审批内容类型</div>
                            <div class="content">{{detailsInfo.app_content_type}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序ID</div>
                            <div class="content">{{detailsInfo.app_id}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序备注</div>
                            <div class="content">{{detailsInfo.app_remark}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-area">
                <div class="area-title">{{details.app_name}}小程序负责人信息</div>
                <div class="list-area bgf">
                    <div class="info-list">
                        <div class="list">
                            <div class="title">小程序负责人姓名</div>
                            <div class="content">{{detailsInfo.app_corp_name}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序负责人证件类型</div>
                            <div class="content">{{detailsInfo.app_corp_id_type}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序负责人证件号</div>
                            <div class="content">{{detailsInfo.app_corp_id_number}}</div>
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="list">
                            <div class="title">证件有效期起始日期</div>
                            <div class="content">{{detailsInfo.app_corp_id_begindate}}</div>
                        </div>
                        <div class="list">
                            <div class="title">证件有效期终止日期</div>
                            <div class="content">{{detailsInfo.app_corp_id_enddate}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序负责人联系电话</div>
                            <div class="content">{{detailsInfo.app_corp_phone}}</div>
                        </div>
                    </div>
                    <div class="info-list">
                        <div class="list">
                            <div class="title">小程序负责人应急联系电话</div>
                            <div class="content">{{detailsInfo.app_corp_phone_back}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序负责人电子邮箱</div>
                            <div class="content">{{detailsInfo.app_corp_email}}</div>
                        </div>
                        <div class="list">
                            <div class="title">小程序其他备注</div>
                            <div class="content">{{detailsInfo.app_remark}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 操作按钮 -->
        <div class="reviewing bgf">
            <div class="btn-box w-box">
                <button class="draft-btn" type="button" @click="goPage('home')">返回首页</button>
                <button class="btn btn-primary save-btn linear-bg" type="button" @click="goPage('home')">申请新的备案</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderId: '',
            details: '',
            detailsInfo: '',
            user: '',
        }
    },
    methods: {
        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('details', res)
                    this.details = res.data.data
                    this.detailsInfo = res.data.data.detail
                })
        },

        // 页面跳转
        goPage(path){
             this.$router.push(`/${path}`)
        },
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        // console.log('当前订单id为：', this.orderId)

        this.getDetails()

        let user = localStorage.getItem('cj_user')
        this.user = user
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;

.bg-box {
    background-color: #f7fbfa;
    min-height: 100%;
}

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }
}

.info-area {
    padding-top: 25px;

    .area-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .list-area {
        padding: 30px 84px 30px 30px;

        .info-list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;

            .list {
                flex-basis: 30%;

                .title {
                    font-size: 14px;
                    color: #969696;
                    margin-bottom: 4px;
                }

                .content {
                    line-height: 44px;
                    padding: 0 15px;
                    font-size: 14px;
                    color: #3e3e3e;
                    background-color: #fafafa;
                    font-weight: bold;
                }
            }
        }
    }
}

// 操作按钮
.reviewing {
    width: 100%;
    padding: 24px 0;
    margin-top: 150px;

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 171px;
            height: 58px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 6px;
            color: #fff;
        }

        .draft-btn {
            margin-right: 12px;
            background-color: #acacac;
            border: none;
        }
    }
}
</style>