<template>
    <div class="bg-box">
        <div class="c-box">
            <div class="left-img">
                <img src="@/assets/img/else/login-img.png" alt />
            </div>
            <div class="login-area">
                <div class="center-box">
                    <div class="title-box">
                        <div class="title">账号登录</div>
                    </div>
                    <form class="form-box">
                        <div class="inp-box md-1">
                            <div class="title">手机号码</div>
                            <input type="tel" class="form-control inp" id="phoneNumber" placeholder="请输入手机号" v-model="tel" />
                        </div>
                        <div class="inp-box">
                            <div class="title">验证码</div>
                            <div class="d-flex align-items-center">
                                <input type="tel" class="form-control inp" id="verificationCode" placeholder="请输入验证码" v-model="code" />
                                <button type="button" class="code-btn" id="sendCodeBtn" @click="getcode" :disabled="btndisabled">{{btnstr}}</button>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary linear-bg w-100 login-btn" @click.prevent="login">登录</button>
                    </form>
                    <div>
                        <div class="form-check d-flex align-items-center p-0 m-0">
                            <input class="form-check-input mt-0 ms-0" type="checkbox" id="flexCheckDefault1" v-model="ckOne" />
                            <label class="form-check-label label-text d-flex align-items-center c-label" for="flexCheckDefault1">
                                登录即视为同意
                                <a href="#" @click.prevent="goPage('agreement')">《服务协议》</a>和
                                <a href="#" @click.prevent="goPage('statement')">《隐私政策》</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tel: '',
            code: '',
            btndisabled: false,
            btnstr: '获取验证码',
            ckOne: false,
        }
    },
    methods: {
        // 获取验证码
        getcode() {
            let reg_phobe = /^1[13456789]\d{9}$/
            //验证手机号码是否合法
            if (this.tel == '') {
                alert('电话号码不能为空')
                return
            }
            if (!reg_phobe.test(this.tel)) {
                alert('手机号码不合法')
                return
            }
            //请求发送验证码
            this.$axios
                .get('/api/sendCode', {
                    params: {
                        phone: this.tel,
                    },
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.code == 200) {
                        // console.log(res.data.msg)
                    }
                })
                .catch((err) => {
                    // console.log('err', err)
                })
            // console.log('发送请求')
            //判断用户输入的验证码和请求的验证码是否一致
            //同时处理获取验证码这一块
            this.timewait(60)
            this.btndisabled = true
        },
        // 获取验证码间隔时间
        timewait(t) {
            let _this = this
            setTimeout(function () {
                if (t >= 0) {
                    _this.btnstr = t + '秒后点击'
                    t--
                    _this.timewait(t)
                } else {
                    _this.btnstr = '发送验证码'
                    t = 60
                    _this.btndisabled = false
                }
            }, 1000)
        },
        // 页面跳转
        goPage(path, i) {
            if (i) {
                this.$router.push({ path: `/${path}`, query: { id: i } })
                return
            }
            this.$router.push(`/${path}`)
        },
        // 登录
        login() {
            let reg_phobe = /^1[13456789]\d{9}$/
            //验证手机号码是否合法
            if (this.tel == '') {
                alert('电话号码不能为空')
                return
            }
            if (!reg_phobe.test(this.tel)) {
                alert('手机号码不合法')
                return
            }
            if (this.code == '') {
                alert('验证码不能为空')
                return
            }
            if (!this.ckOne) {
                alert('请勾选服务协议与隐私政策同意框')
                return
            }
            // console.log('登录')
            //发送验证码登录的请求
            this.$axios
                .post('/api/login', {
                    phone: this.tel,
                    code: this.code,
                })
                .then((res) => {
                    // console.log('res', res)
                    if (res.data.code == 200) {
                        // console.log(res.data.msg)
                        this.$cookies.set('cj_token', res.data.data.token)
                    }
                    let _token = this.$cookies.get('cj_token')
                    //判断是否存在登录信息
                    if (typeof _token === 'string') {
                        localStorage.setItem('cj_user', this.tel)
                        this.$store.dispatch('user/setUsernum', this.tel)
                        this.$router.push('/myorder')
                    } else {
                        //不存在或者token错误
                        alert('登录失败，请重新登录')
                    }
                })
                .catch((err) => {
                    // console.log('err', err)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;

.bg-box {
    background: url(@/assets/img/else/login-bg.png) no-repeat;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-box {
    background-color: #fff;
    display: flex;
    width: 85%;
    height: 772px;

    .left-img,
    .login-area {
        width: 50%;
    }

    .left-img {
        background-color: #f4fbf9;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .login-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .center-box {
            width: 60%;

            .title-box {
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 46px;
            }

            .form-box {
                margin-bottom: 26px;
            }

            .inp-box {
                &.md-1 {
                    margin-bottom: 24px;
                }

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 6px;
                }
            }

            .inp {
                height: 60px;
                border: 1px solid #e4e4e4;
                font-size: 14px;
                box-sizing: border-box;
                border-radius: 5px;

                &::placeholder {
                    font-size: 14px;
                    color: #868686;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &:-webkit-autofill {
                    background: transparent;
                    transition: background-color 50000s ease-in-out 0s;
                    -webkit-text-fill-color: unset;
                }
            }

            .code-btn {
                width: 150px;
                height: 60px;
                box-sizing: border-box;
                margin-left: 8px;
                background-color: transparent;
                border: 1px solid $primary-color;
                color: $primary-color;
                font-size: 14px;
                border-radius: 5px;
            }

            .login-btn {
                height: 65px;
                margin-top: 58px;
                font-size: 16px;
                color: #fff;
            }

            .label-text {
                font-size: 14px;
                color: #888888;

                a {
                    color: #353535;
                    text-decoration: none;
                }
            }

            .form-check {
                min-height: 0 !important;
            }

            .form-check-input {
                width: 18px;
                height: 18px;
                border: 1px solid #dbdbdb;
                border-radius: 1px;
                margin-right: 10px;
            }

            .c-label {
                height: 18px;
                line-height: 18px;
            }
        }
    }
}
</style>