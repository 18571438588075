<template>
    <div class="bg-box">
        <!-- 头部 -->
        <div class="header bgf">
            <div class="w-box">
                <div class="logo" @click="goPage('home')">
                    <img src="@/assets/img/else/logo.png" alt />
                </div>
                <div class="header-nav">
                    <div>我的工单</div>
                    <div @click="goPage('myOrder')">我的备案</div>
                    <div class="active">{{user}}</div>
                </div>
            </div>
        </div>

        <!-- 页面标题+面包屑 -->
        <div class="title-crumb bgf">
            <div class="w-box">
                <div class="page-title">订单{{detailsInfo.order_number}}</div>
                <div class="crumb-box">
                    <div :class="['crumb',(index < 4) ? 'active':'']" v-for="item,index in crumbArray" :key="index">{{item.title}}</div>
                </div>
            </div>
        </div>

        <div class="w-box bgf">
            <div class="list-box">
                <div class="info-list" v-for="item,index in verifyArray" :key="index">
                    <div class="list">
                        <div class="list-title">{{item.name}}</div>
                        <div class="list-content">
                            <div class="content">{{item.value}}</div>
                        </div>
                        <!-- <span class="text-danger msg1">测试提示</span> -->
                    </div>
                    <div class="list">
                        <div class="list-title">验证码</div>
                        <div class="list-content">
                            <div class="inp-box">
                                <input :type="item.type" class="verify_input" placeholder="请输入验证码" v-model="inputCode[index]" />
                                <button type="button" class="code-btn" @click="sendCode(item,index)" :disabled="btnStatus[index].btndisabled">{{btnStatus[index].btnstr}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="qrcode-area bgf w-box" v-if="isPerson">
            <div class="area-title">个人代注册扫脸认证</div>
            <div class="explain-list">
                <div class="explain">1、个人代注册扫二维码，完成操作后。 会收到一条微信服务消息，请按提示完成扫脸认证。</div>
                <div class="explain">2、完成后点击提交按钮。</div>
            </div>
            <div class="qr-code">
                <div class="code-box">
                    <img :src="statusInfo.task_qr_code" alt="图片链接错误" />
                </div>
            </div>
        </div>

        <div class="qrcode-area bgf w-box" v-if="isApplet">
            <div class="area-title">小程序管理员扫脸认证</div>
            <div class="explain-list">
                <div class="explain">1、小程序管理员扫二维码，完成操作后。 会收到一条微信服务消息，请按提示完成扫脸认证。</div>
                <div class="explain">2、完成后点击提交按钮。</div>
            </div>
            <div class="qr-code">
                <div class="code-box">
                    <img :src="statusInfo.admin_qr_code" alt="图片链接错误" />
                </div>
            </div>
        </div>

        <!-- 操作按钮 -->
        <div class="reviewing bgf">
            <div class="btn-box w-box">
                <button class="btn btn-primary save-btn linear-bg" type="button" @click="allVerify">提交认证</button>
                <button class="draft-btn" type="button" @click="goPage('fillinfo',true)">返回上一级</button>
            </div>
        </div>

        <!-- 模态框 -->
        <div class="mark_bg" v-if="isModel">
            <div class="content_box">
                <img src="@/assets/img/else/pass.png" alt />
                <div class="tip_text">恭喜您！认证完成啦~</div>
                <div class="btn_box">
                    <button type="button" class="btn btn-primary go_btn" @click="goPage('home')">创建新备案</button>
                    <button type="button" class="go_affirm" @click="goPage('home')">返回首页</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderId: '', //订单ID
            detailsInfo: '', //详细信息
            verifyArray: [], //字段信息
            statusInfo: '', //状态信息
            isPerson: false, //个人代注册
            isApplet: false, //小程序扫脸
            inputCode: [],
            formData: [],
            user: '',

            btnStatus: [
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
                {
                    btndisabled: false,
                    btnstr: '获取验证码',
                },
            ],

            isModel: false,

            inputs: [],

            // 面包屑
            crumbArray: [
                {
                    title: '确认订单',
                },
                {
                    title: '支付订单',
                },
                {
                    title: '信息填写',
                },
                {
                    title: '认证核验',
                },
                {
                    title: '完成',
                },
            ],
        }
    },
    methods: {
        // 获取订单详情
        getDetails() {
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/detail', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('details', res)
                    this.detailsInfo = res.data.data
                })
        },

        // 获取验证页面字段
        getVerify() {
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/verify', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('verify', res)
                    this.verifyArray = res.data.data
                })
        },

        // 获取订单状态
        getStatus() {
            let _token = this.$cookies.get('cj_token')

            this.$axios
                .post('/api/order/status', {
                    token: _token,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('status', res)
                    this.statusInfo = res.data.data
                    // 如果有小程序管理员授权扫脸
                    if (res.data.data.admin_qr_code) {
                        this.isApplet = true
                    }
                    // 如果有个人代注册授权扫脸
                    if (res.data.data.task_qr_code) {
                        this.isPerson = true
                    }
                })
        },

        // 发送验证码
        sendCode(info, index) {
            let _token = this.$cookies.get('cj_token')
            // console.log('info', info)

            this.$axios
                .post('/api/order/send', {
                    token: _token,
                    value: info.value,
                    type: info.type,
                })
                .then((res) => {
                    // console.log('send', res)
                    console.log(res.data.msg)
                })

            this.timewait(60, index)
            this.btnStatus[index].btndisabled = true
        },

        // 获取验证码间隔时间
        timewait(t, index) {
            let _this = this
            setTimeout(function () {
                if (t >= 0) {
                    _this.btnStatus[index].btnstr = t + '秒后点击'
                    t--
                    _this.timewait(t, index)
                } else {
                    _this.btnStatus[index].btnstr = '获取验证码'
                    t = 60
                    _this.btnStatus[index].btndisabled = false
                }
            }, 1000)
        },

        // 页面统一验证
        allVerify() {
            let _token = this.$cookies.get('cj_token')

            // 是否填写全部验证框
            let allCheck = true

            // 遍历检查是否有空的输入框
            for (let i = 0; i < this.inputCode.length; i++) {
                if (this.inputCode[i] === '' || !this.inputCode[i]) {
                    allCheck = false
                    alert('请填写完整所有验证码')
                    return
                }
            }

            if (this.inputCode.length < 3 || allCheck == false) {
                alert('请填写完整所有验证码')
                return
            }

            const result = []

            for (const [index, item] of this.verifyArray.entries()) {
                // console.log('index', index)
                const newItem = { value: item.value, type: item.type, code: this.inputCode[index] }
                result.push(newItem)
            }

            // 这里的 result 就是你想要的结果
            // console.log('二维数组', result)

            this.$axios
                .post('/api/order/verifyForm', {
                    token: _token,
                    form: result,
                    order: this.orderId,
                })
                .then((res) => {
                    // console.log('verifyForm', res)
                    if (res.data.code == 200) {
                        this.isModel = true
                    }
                    if (res.data.code == 500) {
                        alert(res.data.msg)
                    }
                })
        },

        // 页面跳转
        goPage(path, i) {
            this.isModel = false
            if (i) {
                // 回上一级信息填写页
                this.$router.push({ path: `/${path}`, query: { id: this.orderId } })
            } else {
                this.$router.push(`/${path}`)
            }
        },
    },
    mounted() {
        // 进入页面后平滑至顶部
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // 使用平滑滚动效果
        })
    },
    created() {
        // 获取订单ID
        this.orderId = this.$route.query.id
        // console.log('当前订单id为：', this.orderId)

        let user = localStorage.getItem('cj_user')
        this.user = user

        if (this.orderId) {
            this.getDetails()
            this.getVerify()
            this.getStatus()
        }
    },
}
</script>

<style lang="scss" scoped>
$primary-color: #70c6c0;
$assist-color: #47958f;

.bg-box {
    background-color: #f7fbfa;
    min-height: 100%;
}

.bgf {
    background-color: #fff;
}

// 头部
.header {
    height: 76px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .w-box {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }

    .header-nav {
        display: flex;
        align-items: center;

        > div {
            color: #767676;
            font-size: 14px;
            margin-right: 40px;
            cursor: pointer;
            user-select: none;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $primary-color;
            }
        }
    }
}

// 页面标题+面包屑
.title-crumb {
    height: 75px;

    .w-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-title {
        font-size: 20px;
        font-weight: bold;
    }

    .crumb-box {
        display: flex;

        .crumb {
            height: 26px;
            line-height: 26px;
            padding: 0px 12px 0 24px;
            font-size: 14px;
            color: #a1a1a1;
            margin-right: 14px;
            position: relative;
            background-color: #e3e3e3;

            &::before,
            &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 12px solid transparent;
            }

            &::before {
                left: 0;
                top: 0;
                border-left-color: #fff;
            }

            &::after {
                right: -24px;
                top: 0;
                border-left-color: #e3e3e3;
            }

            &:first-child {
                padding-left: 12px;

                &::before {
                    content: none;
                }
            }

            &:last-child {
                margin-right: 0;
                &::after {
                    content: none;
                }
            }
        }

        .active {
            background-color: $primary-color;
            color: #fff;

            &::after {
                border-left-color: $primary-color;
            }
        }
    }
}

// 验证码区域
.list-box {
    display: flex;
    justify-content: space-between;
    padding: 34px 46px 34px 34px;
    margin-top: 32px;

    .info-list {
        flex-basis: 30%;

        .list {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            .list-title {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 6px;
            }

            .list-content {
                height: 44px;
                border-radius: 3px;
                overflow: hidden;

                .content {
                    height: 44px;
                    line-height: 44px;
                    border: 1px solid #ececec;
                    padding: 0 15px;
                    font-size: 14px;
                }

                .inp-box {
                    height: 44px;
                    display: flex;
                    align-items: center;

                    input {
                        font-size: 14px;
                        border: none;
                        height: 44px;
                        border: 1px solid #ececec;
                        border-right: none;
                        flex-grow: 1;
                        padding-left: 16px;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &::placeholder {
                            font-size: 12px;
                            color: #a3a3a3;
                        }
                    }

                    button {
                        width: 96px;
                        height: 44px;
                        border: none;
                        font-size: 14px;
                        color: #fff;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
}

// 二维码区域
.qrcode-area {
    padding: 34px;
    margin-top: 30px;

    .area-title {
        font-weight: bold;
        margin-bottom: 20px;
    }

    .explain-list {
        margin-bottom: 30px;

        .explain {
            color: #676767;
            font-size: 14px;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .qr-code {
        display: flex;
        align-items: center;

        .code-box {
            width: 168px;

            img {
                width: 100%;
            }
        }
    }
}

// 操作按钮
.reviewing {
    width: 100%;
    padding: 24px 0;
    margin-top: 120px;

    .btn-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 171px;
            height: 58px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            border-radius: 6px;
            color: #fff;
        }

        .draft-btn {
            margin-left: 12px;
            background-color: #acacac;
            border: none;
        }
    }
}

// 提交后模态框
.mark_bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba($color: #000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .content_box {
        width: 568px;
        height: 356px;
        background-color: #fff;
        border-radius: 10px;
        padding: 60px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tip_text {
            margin: 12px 0 46px;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                width: 130px;
                height: 40px;
                font-size: 14px;
                letter-spacing: 1px;
                border-radius: 20px;
                padding: 6px 0px;
                color: #fff;
                box-sizing: border-box;
                user-select: none;
            }

            .go_btn {
                background-color: $assist-color;
                border-color: $assist-color;
            }

            .go_affirm {
                background-color: #fff;
                margin-left: 16px;
                border: 1px solid #c4c4c4;
                color: #535353;
            }
        }
    }
}
</style>